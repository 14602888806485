<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-form-item label="流水号:">
          <el-input v-model="searchForm.id" clearable />
        </el-form-item>
        <el-form-item label="学生姓名:">
          <el-input v-model="searchForm.studentname" clearable />
        </el-form-item>
        <el-form-item label="项目类型:">
          <el-input v-model="searchForm.projecttype" clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input v-model="searchForm.schoolorgname" clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{padding:0}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="流水号" prop="id" align="center" />
      <el-table-column label="学生姓名" align="center">
        <template slot-scope="scope" align="center">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.studentname
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="项目类型" prop="projecttype" align="center" />
      <el-table-column
        label="班别"
        prop="fudaoclasstypename"
        align="center"
        width="300"
      />
      <el-table-column label="跟进人" prop="followusername" align="center" />
      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          {{ statusType[scope.row.status] }}
        </template>
      </el-table-column>

      <el-table-column
        label="所属教学点名称"
        prop="schoolorgname"
        align="center"
      />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
			:close-on-click-modal='false'
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>{{ dialogTitle }}</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
          {{ dynamicValidateForm.id }}
        </el-form-item>
        <el-form-item label="状态:">
          {{ statusType[dynamicValidateForm.status] }}
        </el-form-item>
        <el-form-item label="学生姓名:">
          {{ dynamicValidateForm.studentname }}
        </el-form-item>
        <el-form-item label="手机号:">
          {{ dynamicValidateForm.mobile }}
        </el-form-item>
        <el-form-item label="班别:">
          {{ dynamicValidateForm.fudaoclasstypename }}
        </el-form-item>
        <el-form-item label="项目类型:">
          {{ dynamicValidateForm.projecttype }}
        </el-form-item>
        <el-form-item label="跟进人:">
          {{ dynamicValidateForm.followusername }}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="分公司:">
          {{ dynamicValidateForm.companyorgname }}
        </el-form-item>
        <el-form-item label="录入时间:">
          {{
            dynamicValidateForm.inserttime | formatDate("YYYY-MM-DD HH:mm:ss")
          }}
        </el-form-item>
        <el-form-item label="备注:">
          {{ dynamicValidateForm.remark }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="35%"
			v-dialogDrag
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="姓名" prop="studentname">
          <el-input v-model="dynamicValidateForm.studentname" disabled />
          <el-button
            v-if="!isDisable"
            type="primary"
            class="select-btn"
            @click="
              onSelectData(
                techfudao_dialogresult,
                'showDeliModal',
                {schoolorgid:dynamicValidateForm.schoolorgid},
              )
            "
            >选择德立学生</el-button
          >
        </el-form-item>
        <el-form-item label="状态:" prop="status">
          <el-select v-model="dynamicValidateForm.status">
            <el-option value="1" label="一般"></el-option>
            <el-option value="2" label="良好"></el-option>
            <el-option value="3" label="优"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            v-model="dynamicValidateForm.remark"
            placeholder="请填写备注"
						clearable
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择德立学生-->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择德立学生："
      :visible.sync="showDeliModal"
			:close-on-click-modal='false'
			v-if="showDeliModal"
      append-to-body
      
    >
      <el-button type="primary" @click="onConfirmDeli">确定</el-button>
      <el-form label-width="50px" :model="selectSearchForm">
        <el-input
          v-model="selectSearchForm.studentname"
          placeholder="学生姓名"
          clearable
        />
        <el-input
          v-model="selectSearchForm.fudaoclasstypename"
          placeholder="班别"
          clearable
        />
        <el-button
          type="primary"
          icon="el-icon-search"
          class="seachBtn"
          @click="onSelectSearchClick(selectTableUrl,null,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
          >搜索</el-button
        >
      </el-form>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="学生姓名" prop="studentname" align="center" />
        <el-table-column
          label="班别名称"
          prop="fudaoclasstypename"
          align="center"
        />
        <el-table-column label="培训类型" prop="peixunproject" align="center" />
        <el-table-column label="报读时间" align="center">
          <template slot-scope="scope">
            {{ scope.row.regdate | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="跟进人" prop="followusername" align="center" />
        <el-table-column
          label="所属教学点"
          prop="schoolorgname"
          align="center"
        />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,Object.assign({schoolorgid:dynamicValidateForm.schoolorgid},selectSearchForm))"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <Export
      :exportTitle="'教师管理'"
      :transferDialog="transferDialog"
      :exportUrl="exportUrl"
      :exportexcelUrl="exportexcelUrl"
      :exportsaveUrl="exportsaveUrl"
      studentstudy_exportsave
      :searchForm="searchForm"
      @onCloseExport="onCloseExport"
    ></Export>

    <Import
      :importDialog="importDialog"
      :importSaveUrl="importSaveUrl"
      @onCloseImport="onCloseImport"
      @getDataList="getDataList"
      downloadUrl="download/教学管理/技能/技能学生学习状态导入模板.xls"
    ></Import>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import Export from "@/components/Export/index.vue";
import Import from "@/components/Import/index.vue";

import {
  studentstudy_list,
  studentstudy_input,
  studentstudy_save,
  studentstudy_delete,
  studentstudy_export,
  studentstudy_exportexcel,
  studentstudy_exportsave,
  studentstudy_importsave,
  techfudao_dialogresult,
  techfudao_gettechfudao,
} from "@/request/api/allChildrenProject";
import { _pictureupload_save } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "studentstudy",
  components: { Export, Import },
  props: {
    techfudao_dialogresult: {
      default: () => techfudao_dialogresult,
    },
    exportUrl: {
      default: () => studentstudy_export,
    },
    exportexcelUrl: {
      default: () => studentstudy_exportexcel,
    },
    exportsaveUrl: {
      default: () => studentstudy_exportsave,
    },
    importSaveUrl: {
      default: () => studentstudy_importsave,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      transferDialog: false, //导出框
      importDialog: false, //导入框

      itemId: null, //首表格项id
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, // 添加
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgid: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgid: [{ trigger: "blur", message: "必填字段", required: true }],
        status: [{ trigger: "blur", message: "必填字段", required: true }],
        studentname: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      showDeliModal: false, //德立学生
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectTableUrl: null,
      selectTable: {},
      
      selectSchoolorgid: null,
      selectSearchForm: {},
			key: '',
      isDisable: false,
      statusType: {
        1: "一般",
        2: "良好",
        3: "优",
      },
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
    //关闭导入框
    onCloseImport(bool) {
      this.importDialog = bool;
    },
    //导入 click
    btnImport() {
      this.importDialog = true;
    },
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: studentstudy_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },

    // 查看详情路由页 api
    btnShowDetail(row) {
      myRequest({
        url: studentstudy_input,
        data: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showDetailModal = true;
          this.dialogTitle = "查看";
          this.dynamicValidateForm = {};
          this.dynamicValidateForm = res.data.data.vstudentstudy;
        }
      });
    },

    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.isDisable = false;
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(this.dynamicValidateForm,'companyorgname',this.$store.state.login.userinfo.companyorgname)
      this.$set(this.dynamicValidateForm,'schoolorgname',this.$store.state.login.userinfo.schoolorgname)
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.isDisable = true;
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile() {
      myRequest({
        method: "post",
        url: _pictureupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.fileurl = res.data.url;
        }
      });
    },
    //确认德立学生 click
    onConfirmDeli() {
      selectCheck(this.multipleSelection, "确认德立学生", false, () => {
        this.$set(this.dynamicValidateForm,'studentname',this.multipleSelection[0].studentname)
        this.dynamicValidateForm.techfudaoid = this.multipleSelection[0].id;
        this.showDeliModal = false;
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },

    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: studentstudy_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.showAddModal = true;
          this.dynamicValidateForm = res.data.data.vstudentstudy;
          this.dynamicValidateForm.status = this.statusType[
            this.dynamicValidateForm.status
          ];
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: studentstudy_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,

              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: studentstudy_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },
    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
    },

    

    //选项表格 api(德立学生)
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },

    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style  lang="scss">
</style>