<template>
  <div id="import">
    <el-dialog
      class="btn-2b5a95"
      title="导入文件数据"
      :visible.sync="importDialog"
      v-if="importDialog"
      width="30%"
      center
			:before-close="onCloseImport"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-upload
        class="upload-demo"
        name="file"
        ref="upload"
        action=""
        accept=".xls, .xlsx"
        :on-change="handleChange"
        :fileList="fileList"
      >
        <el-button slot="trigger" type="primary">选择文件</el-button>
        <el-button
          class="download"
          type="success"
          @click="download(downloadUrl)"
          >下载模板</el-button
        >
        <div slot="tip" class="el-upload__tip" v-if="!resTipC">
          只能上传以.xls为后缀的文件
        </div>
        <!-- 响应提示 -->
        <div v-else>
          <h2>提示：</h2>
          <div v-html="resTipC" class="resTipc-w"></div>
        </div>
      </el-upload>

      <div slot="footer" class="dialog-footer">
        <el-button @click="onCloseImport">取 消</el-button>
        <el-button
          type="primary"
          @click="onImportSave"
          :loading="!$store.state.global.isEndRequest"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { myRequest } from "@/request/index";
import { _filedownload_save } from "@/request/api/public";
import { messageTip } from "@/utils/util";
export default {
  name: "import",
  props: {
    importDialog: Boolean,
    importSaveUrl: String,
    downloadUrl: String,
  },

  data() {
    return {
      fileList: [], //上传的文件列表，仅作展示
      file: new FormData(), //上传的文件表单
      resTipC: "", //响应提示 object
      msgType: "", //提示信息类型
    };
  },

  methods: {
    //上传文件改变时
    handleChange(file, fileList) {
      this.file = new FormData();
      this.file.append("scorefile", file.raw);
      this.fileList = [{ name: file.name, url: file.name }];
    },
    //确定导入 click api
    onImportSave() {
      if (!this.file.get("scorefile")) {
        return messageTip({
          message: "请选择需要导入的文件",
          type: "warning",
        });
      }
      myRequest(
        {
          method: "post",
          url: this.importSaveUrl,
          data: this.file,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        {
          disableTip: true,
        }
      ).then((res) => {
        if (res.data.code === "200" && res.data.object === null) {
          //上传成功
          this.msgType = "success";
          this.resTipC = res.data.msg;
          this.onCloseImport();
          this.$emit("getDataList");
        } else {
          //上传失败
          this.msgType = "warning";
        }
        if (res.data.object !== null) {
          this.resTipC = res.data.object;
        }
        messageTip({
          message: res.data.msg,
          type: this.msgType,
        });
      });
    },

    // 下载模板 click
    download(url) {
      myRequest({
        url: _filedownload_save,
        data: {
          fileurl: url,
        },
      }).then((res) => {
        if (res.status === 200) {
          location.href =
            this.$url.Freemarker + _filedownload_save + "?fileurl=" + url;
        }
      });
    },

    //关闭导入框
    onCloseImport() {
      this.resTipC = "";
      this.fileList = [];
      this.file = new FormData();
      this.$emit("onCloseImport", false);
    },
  },
};
</script>

<style scoped lang='scss'>
.resTipc-w {
  color: red;
}

.download {
  margin-left: 10px;
}
</style>